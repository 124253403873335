import React from 'react'

const Footer = (props) => {
    // console.log(props)
    return (
        <div className='footer' onClick={() => {window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
          })}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g id="Play" transform="translate(-992 -998)">
                  <g id="Tracciato_3" data-name="Tracciato 3" transform="translate(992 998)" fill="none">
                    <path d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z" stroke="none"/>
                    <path d="M 24 2 C 21.02914047241211 2 18.1481990814209 2.581401824951172 15.43722152709961 3.728050231933594 C 12.81771850585938 4.836009979248047 10.46474075317383 6.422561645507812 8.443649291992188 8.443649291992188 C 6.422561645507812 10.46474075317383 4.836009979248047 12.81771850585938 3.728050231933594 15.43722152709961 C 2.581401824951172 18.1481990814209 2 21.02914047241211 2 24 C 2 26.97085952758789 2.581401824951172 29.8518009185791 3.728050231933594 32.56278228759766 C 4.836009979248047 35.18228149414062 6.422561645507812 37.53525924682617 8.443649291992188 39.55635070800781 C 10.46474075317383 41.57743835449219 12.81771850585938 43.16399002075195 15.43722152709961 44.27194976806641 C 18.1481990814209 45.41860198974609 21.02914047241211 46 24 46 C 26.97085952758789 46 29.8518009185791 45.41860198974609 32.56278228759766 44.27194976806641 C 35.18228149414062 43.16399002075195 37.53525924682617 41.57743835449219 39.55635070800781 39.55635070800781 C 41.57743835449219 37.53525924682617 43.16399002075195 35.18228149414062 44.27194976806641 32.56278228759766 C 45.41860198974609 29.8518009185791 46 26.97085952758789 46 24 C 46 21.02914047241211 45.41860198974609 18.1481990814209 44.27194976806641 15.43722152709961 C 43.16399002075195 12.81771850585938 41.57743835449219 10.46474075317383 39.55635070800781 8.443649291992188 C 37.53525924682617 6.422561645507812 35.18228149414062 4.836009979248047 32.56278228759766 3.728050231933594 C 29.8518009185791 2.581401824951172 26.97085952758789 2 24 2 M 24 0 C 37.25482940673828 0 48 10.74517059326172 48 24 C 48 37.25482940673828 37.25482940673828 48 24 48 C 10.74517059326172 48 0 37.25482940673828 0 24 C 0 10.74517059326172 10.74517059326172 0 24 0 Z" stroke="none" fill="#000"/>
                  </g>
                  <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M14.546,14.131l6.318,6.323a1.194,1.194,0,0,0,1.687-1.692L15.391,11.6a1.192,1.192,0,0,0-1.647-.035L6.536,18.758a1.194,1.194,0,1,0,1.687,1.692Z" transform="translate(1001.813 1005.75)"/>
                </g>
              </svg>
        </div>
    )
}
export default Footer