import React, { Component } from 'react'
import TextLayout from '../components/TextLayout'

class Contacts extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <TextLayout>
                <div className="txtContacts">
                    GOT QUESTIONS?
                    <span>Let's talk</span>
                    <ul>
                        <li>Email: <a href="mailto:samischinaia@gmail.com">samischinaia@gmail.com</a></li>
                        <li>IG: <a target="_blank" href="https://www.instagram.com/sami.schinaia/" rel="noreferrer">sami.schinaia</a></li>
                    </ul>
                    <span>
                        Reps
                    </span>
                    <ul>
                        <li>Italy: <a target="_blank" href="https://puromgmt.com/" rel="noreferrer">@puromgmt</a></li>
                        <li><a href="mailto:simona@puromgmt.com">simona@puromgmt.com</a></li>
                    </ul>
                    <ul>
                        <li>Middle-East: <a target="_blank" href="https://theitalianjob.it/" rel="noreferrer">@theitalianjob</a></li>
                        <li><a href="mailto:oscar@theitalianjob.it">oscar@theitalianjob.it</a></li>
                    </ul>
                    <span>Partner and creative director</span>
                    <ul>
                        <li><a href="https://hipcool.studio/" target="_blank" rel="noreferrer">@hipcool.studio</a></li>
                    </ul>
                </div>
            </TextLayout>
        )
    }
}

export default Contacts